import { ClickAwayListener } from 'components'
import { Modal, ModalSizes } from 'flowbite-react'
import React, { FC, useEffect, useState } from 'react'
import { IoCloseSharp } from "react-icons/io5";

interface props {
    isOpen?: boolean
    onClose: () => void
    title?: string
    MainCompoenent?: React.ReactNode
    size?: keyof ModalSizes
    className?: string
    isSecond?: boolean
}

const CusModal: FC<props> = ({ isOpen, onClose, title, MainCompoenent, size, className, isSecond }) => {
    const [isExtElemOpened, setIsExtElemOpened] = useState<boolean>(false)
    const handleEvent = (event: CustomEvent) => {
        if (Boolean(event.detail.message)) {
            setIsExtElemOpened(event?.detail?.message?.isOpen)
        }
    };

    useEffect(() => {

        window.addEventListener('datepickerOpened', handleEvent as EventListener);

        return () => {
            window.removeEventListener('datepickerOpened', handleEvent as EventListener);
        };
    }, []);


    return (
        <Modal dir='rtl' show={isOpen} onClose={() => !isExtElemOpened && onClose()} size={size}>
            <ClickAwayListener onClickAway={() => !isExtElemOpened && onClose()}>
                {title && <div id='modal-header' className='bg-palette-background-default py-3 px-4 rounded-t-lg flex justify-between items-center'>
                    <p>
                        {title}
                    </p>
                    <button
                        onClick={() => !isExtElemOpened && onClose()}
                        className='hover:bg-white/50 hover:drop-shadow-lg active:drop-shadow-none active:shadow-inner p-2 rounded-lg'>
                        <IoCloseSharp />
                    </button>
                </div>}
                <Modal.Body id={isSecond ? "second-modal" : "first-modal"} className={`${title === "اطلاعات مشتری" && "h-[80vh]"} px-4 ${className}`}>
                    {MainCompoenent}
                </Modal.Body>
            </ClickAwayListener>
        </Modal>
    )
}

export default CusModal