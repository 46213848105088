import React, { FC } from "react";

const CircleDownArrow: FC<React.SVGProps<SVGSVGElement>> = ({ className }) => {
  return (
    <svg
      className={className}
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.2065 8.26781C19.2065 12.8267 14.9206 16.5356 9.6526 16.5356C4.38456 16.5356 0.0986589 12.8267 0.0986591 8.26781C0.0986593 3.70894 4.38456 -5.60672e-07 9.6526 -3.61398e-07C14.9206 -1.62123e-07 19.2065 3.70894 19.2065 8.26781ZM17.7735 8.26781C17.7735 4.39269 14.1305 1.24017 9.6526 1.24017C5.17467 1.24017 1.53175 4.39269 1.53175 8.26781C1.53175 12.1429 5.17467 15.2955 9.6526 15.2955C14.1305 15.2955 17.7735 12.1429 17.7735 8.26781ZM13.4764 7.51346L10.1602 10.3956C10.0255 10.5122 9.84301 10.5775 9.65289 10.5775C9.46181 10.5775 9.27933 10.5122 9.14462 10.3956L5.8294 7.51346C5.68991 7.39275 5.62017 7.23401 5.62017 7.07609C5.62017 6.91735 5.68991 6.75778 5.83131 6.63707C6.1122 6.39565 6.56505 6.39648 6.84403 6.63872L9.65289 9.07938L12.4608 6.63872C12.7398 6.39648 13.1936 6.39565 13.4745 6.63707C13.7563 6.87849 13.7544 7.27038 13.4764 7.51346Z"
        fill="black"
        fill-opacity="0.44"
      />
    </svg>
  );
};

export default CircleDownArrow;
