import React from "react";
import { RotatingLines } from "react-loader-spinner";

type props = {
  width?: number;
  color?: string;
};

const MiniLoading = ({ width, color }: props) => {
  return (
    <div className="scale-[2]">
      <RotatingLines width={`${width ?? "20"}`} strokeWidth="4" strokeColor={color ?? "gray"} />
    </div>
  );
};

export default MiniLoading;
