import React, { FC, useEffect, useState } from 'react'
import { NewCategory, addNewCategoryApi, updateCategory } from 'api'
import { PenOutline } from 'assets'
import { CusButton, CusInput, ImageUploader } from 'common/modules'
import { useForm } from 'react-hook-form'
import { CategoryType } from 'common/types'

type props = {
  item?: CategoryType
  handleUpdate?: (type: string, value: any, newProperties: any) => Promise<void>
  handleAddNewItem?: (type: string, newItem: any) => Promise<void>
}

const RestaurantTypeForm: FC<props> = ({ item, handleUpdate, handleAddNewItem }) => {

  const [loading, setLoading] = useState<boolean>(false)

  const { register, handleSubmit, setValue, watch } = useForm<NewCategory>()

  const imagesGen = () => {
    const imageUrl = watch("catImage")
    if (typeof imageUrl === "string") {
      return [imageUrl]
    }
  }

  const images = imagesGen()

  useEffect(() => {
    if (item) {
      setValue("catImage", item?.catImage)
      setValue("name", item?.name)
      setValue("persianName", item.persianName)
    }
  }, [item])

  const onSubmit = (data: NewCategory) => {
    setLoading(true)
    const formdata = new FormData()
    if (data.name && data.catImage && data.persianName) {
      formdata.append("name", data.name)
      formdata.append("persianName", data.persianName)
      formdata.append("catImage", data.catImage)
      if (item) {
        updateCategory(item.name, formdata).then((res) => {
          if (res.success) {
            handleUpdate?.("cat", item?._id, res.data)
          }
        }).finally(() => setLoading(false))
        return
      }
    }
    addNewCategoryApi(formdata).then((res) => {
      if (typeof res !== "string" && typeof res !== "number") {
        if (res.success) {
          handleAddNewItem?.("cat", res.data)
        }
      }
    }).finally(() => setLoading(false))
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='flex flex-col gap-y-4'>
        <CusInput regFunc={() => register("persianName")} inputStyle={{ border: 0 }} placeHolder='نوع رستوران' leftIcon={<PenOutline />} />
        <CusInput regFunc={() => register("name")} inputStyle={{ border: 0 }} placeHolder='نوع رستوران (انگلیسی)' leftIcon={<PenOutline />} />
        <ImageUploader style={{ width: "100%", height: "200px" }} defaultImageUrls={images} onUpload={(file) => setValue("catImage", file)} />
        <CusButton loading={loading} type='submit'>ثبت</CusButton>
      </div>
    </form>
  )
}

export default RestaurantTypeForm