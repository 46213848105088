import { DeleteIcon, UploadIcon } from 'assets';
import React, { useState, useEffect } from 'react';
import CusButton from '../CusButton/CusButton';
import { MiniLoading } from 'components/common';
import CusImage from '../CusImage/CusImage';

interface ImageUploadProps {
    isMultiple?: boolean;
    style?: React.CSSProperties
    onUpload?: (file: File) => void;
    defaultImageUrls?: (string | File | undefined)[];
    onDelete?: (newArr: (string | File | undefined)[], imgindx: number) => void
}

const ImageUpload: React.FC<ImageUploadProps> = ({ defaultImageUrls = [], onUpload, isMultiple = false, style, onDelete }) => {
    const [selectedImages, setSelectedImages] = useState<(string | File | undefined)[]>([]);
    const [loading, setLoading] = useState<boolean[]>(Array(isMultiple ? 4 : 1).fill(false));
    const [tempImages, setTempImages] = useState<(string | File | undefined)[]>([])
    const realImages = tempImages.filter(Boolean)

    useEffect(() => {
        if (defaultImageUrls.length > 0) {
            const images = isMultiple ? defaultImageUrls : [defaultImageUrls[0] || '']
            setSelectedImages(images);
            setTempImages(images)
        }
    }, [defaultImageUrls, isMultiple]);

    const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const file = event.target.files?.[0];
        if (file) {
            if (isMultiple) {
                const newSelectedImages = [...selectedImages];
                newSelectedImages[index] = URL.createObjectURL(file);
                setTempImages(newSelectedImages)
                setSelectedImages([...selectedImages, file]);
            } else {
                setSelectedImages([URL.createObjectURL(file)]);
                setTempImages([URL.createObjectURL(file)])
            }

            const newLoading = [...loading];
            newLoading[index] = true;
            setLoading(newLoading);

            try {
                onUpload?.(file);
            } catch (error) {
                console.error('Upload failed', error);
            } finally {
                newLoading[index] = false;
                setLoading(newLoading);
            }
        }
    };

    const handleDeleteImg = (imgindx?: number) => {
        if (!isMultiple && imgindx === 0) {
            setSelectedImages([])
            setTempImages([])
            onDelete?.([], imgindx)
            return
        }
        if (imgindx || imgindx === 0) {
            const newSelectedImages = [...selectedImages];
            newSelectedImages.splice(imgindx, 1);
            setSelectedImages(newSelectedImages);
            const newTempImages = [...tempImages];
            newTempImages.splice(imgindx, 1);
            setTempImages(newTempImages);
            onDelete?.(newSelectedImages, imgindx)
        }
    }

    return (
        <div className='bg-appGray/20 rounded-lg w-full aspect-video relative px-4 py-2 h-[215px]'>
            <div className={`flex flex-wrap h-full ${isMultiple ? "justify-between" : "justify-center items-center"}`}>
                {realImages.map((image, index) => (
                    <div key={index}>
                        {loading[index] ? (
                            // <p>Loading...</p>
                            <MiniLoading color='black' />
                        ) : (
                            <button className='relative' onClick={() => document.getElementById(`image-btn-${index}`)}>
                                <button onClick={() => handleDeleteImg(index)} className='absolute -top-2 -left-2 p-2 bg-rose-200 rounded-full z-50 bg-opacity-70 drop-shadow-md'>
                                    <DeleteIcon className='w-4 h-4' />
                                </button>
                                <CusImage
                                    style={style}
                                    src={image}
                                    alt={`Selected ${index + 1}`}
                                    className='w-24 h-24 object-cover drop-shadow-lg'
                                />
                            </button>
                        )}
                        <input
                            type="file"
                            accept="image/*"
                            className='hidden'
                            id={`image-btn-${index}`}
                            onChange={(event) => handleImageChange(event, index)}
                        />
                    </div>
                ))}
                {isMultiple && realImages.length < 4 && (
                    <div>
                        {realImages.length < 1 && <button className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'><UploadIcon /></button>}
                        <CusButton className='py-0 absolute bottom-4 left-4 text-black' onClick={() => document.getElementById("upload-image-btn-multiple")?.click()}>
                            بارگزاری فایل
                        </CusButton>
                        <input
                            className='hidden'
                            id='upload-image-btn-multiple'
                            type="file"
                            accept="image/*"
                            onChange={(event) => handleImageChange(event, selectedImages.length)}
                        />
                    </div>
                )}
                {!isMultiple && (
                    <div>
                        {realImages.length < 1 && <button className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'><UploadIcon /></button>}
                        <CusButton className='py-0 absolute bottom-4 left-4 text-black' onClick={() => document.getElementById("upload-image-btn-nonMultiple")?.click()}>
                            بارگزاری فایل
                        </CusButton>
                        <input
                            className='hidden'
                            id='upload-image-btn-nonMultiple'
                            type="file"
                            accept="image/*"
                            onChange={(event) => handleImageChange(event, selectedImages.length)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageUpload;
