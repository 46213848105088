import AxiosInstance from "IAxios/AxiosInstance";
import { SendCustoemrNotificationAPI } from "api/customers";
import { CompleteOnwerType, OwnerNotificationType, PagintaionType, UpdateOwnerType } from "common/types";
import { SendCustomerNotification } from "common/types/customers";
import { toast } from "react-toastify";

export interface AllOwnersAPI extends PagintaionType {
    data?: CompleteOnwerType[]
}

export const getAllOwners = (searchTerms?: { mphone?: string, nationalCode?: string, page?: number }) =>
    AxiosInstance.get<AllOwnersAPI>("owners/", { params: { ...searchTerms, limit: 10 } })
        .then((res) => res.data)
        .catch((err) => toast.error(err?.response?.data.error));

export const getSingleOwner = (ownerId: string) =>
    AxiosInstance.get(`owners/${ownerId}`)
        .then((res) => res.data)
        .catch((err) => toast.error(err?.response?.data.error));

export interface GetOwnerNotififcationsAPI extends PagintaionType {
    data?: OwnerNotificationType[]
}

export const getOwnerNotififcations = (params?: { page: number }) =>
    AxiosInstance.post<GetOwnerNotififcationsAPI>("owners/notifications", { type: "همه" }, { params })
        .then((res) => res.data)
        .catch((err) => toast.error(err?.response?.data.error));

export const deleteOnwerNotification = (data: { notificationId: string }) =>
    AxiosInstance.delete("owners/notification", { data })
        .then((res) => res.data)
        .catch((err) => toast.error(err?.response?.data.error));

export const sendOwnerNotification = (data: SendCustomerNotification) =>
    AxiosInstance.post<SendCustoemrNotificationAPI>("owners/notification", data)
        .then((res) => res.data)
        .catch((err) => toast.error(err?.response?.data.error))


export const updateOwnerInformation = (ownerId?: string, data?: Partial<UpdateOwnerType>) =>
    AxiosInstance.put(`owners/${ownerId}`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
        .then((res) => res.data)
        .catch((err) => toast.error(err?.response?.data.error))