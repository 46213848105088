// BarChart.tsx
import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartData,
    ChartOptions
} from 'chart.js';

// Register the components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface BarChartProps {
    labels: string[];
    values: number[];
    colors?: string[];
    thickness?: number;
}

const BarChart: React.FC<BarChartProps> = ({ labels, values, colors, thickness }) => {
    const data: ChartData<'bar'> = {
        labels: labels,
        datasets: [
            {
                label: 'My Dataset',
                data: values,
                backgroundColor: colors || 'rgba(75, 192, 192, 0.2)',
                borderColor: colors ? colors.map(color => color.replace('1', '0.2')) : 'rgba(75, 192, 192, 1)', // Adjust border color based on provided colors
                borderWidth: 1,
            },
        ],
    };

    const options: ChartOptions<'bar'> = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: 'Bar Chart',
            },
            tooltip: {
                titleFont(ctx, options) {
                    return {
                        size: 16,
                        weight: 'bold',
                    };
                },
                bodyFont(ctx, options) {
                    return {
                        size: 10,
                    };
                },
                callbacks: {
                    label: (tooltipItem) => {
                        const value = tooltipItem.raw ?? ""
                        return `فروش : ${value}`;
                    },
                }
            }
        },
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return <Bar data={data} options={options} />;
};

export default BarChart;
