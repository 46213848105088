import DashboardIcon from "assets/icons/DashboardIcon";
import { generateBreadcrumbText } from "common/utils";
import React from "react";
import { useLocation } from "react-router";

const Breadcrumbs = () => {
  const { pathname } = useLocation();
  const BRDText = generateBreadcrumbText(pathname);
  return (
    <div dir="rtl" className="p-4 flex items-center gap-x-4 text-lg">
      <DashboardIcon fill="black" />
      <p>داشبورد</p>
      <p className="text-palette-primary-light">
        <span className="ml-2 -mr-2">/</span>
        {BRDText}
      </p>
    </div>
  );
};

export default Breadcrumbs;
