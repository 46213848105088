import AxiosInstance from "IAxios/AxiosInstance";
import { PagintaionType, SubscriptionType } from "common/types";
import { toast } from "react-toastify";

export interface AllSubscriptionAPIType extends PagintaionType {
    data?: SubscriptionType[]
}

export interface SearchSubscriptionData {
    orderNumber?: string,
    trackId?: string,
    isPaid?: boolean,
    startDate?: string,
    endDate?: string,
    restaurantName?: string
}

export const getAllSubscriptions = (page?: number, searchData?: SearchSubscriptionData) =>
    AxiosInstance.get<AllSubscriptionAPIType>("subscriptions", { params: { page, limit: 10, ...searchData } })
        .then((res) => res.data)
        .catch((err) => toast.error(err?.response?.data.error))