import React, { FC } from 'react'

const OutlineDeleteIcon: FC<React.SVGProps<SVGSVGElement>> = ({ className }) => {
    return (
        <svg className={className} width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.315 6.78784C13.6507 6.82219 13.901 7.18685 13.8741 7.60404C13.8693 7.67273 13.4288 14.4518 13.1753 17.2953C13.0176 19.06 12.0798 20.1338 10.6642 20.1662C9.58089 20.1894 8.53581 20.2025 7.5143 20.2025C6.41315 20.2025 5.33964 20.1874 4.27749 20.1601C2.91873 20.1268 1.97849 19.0318 1.8249 17.3024C1.56891 14.4336 1.13089 7.67172 1.12683 7.60404C1.0992 7.18685 1.3495 6.82118 1.68512 6.78784C2.01587 6.77673 2.31493 7.06563 2.34175 7.48181C2.34434 7.52566 2.52341 10.287 2.71855 13.0191L2.75774 13.5643C2.85602 14.9223 2.95565 16.2272 3.03657 17.1357C3.12352 18.1186 3.55017 18.6257 4.30269 18.6449C6.33433 18.6984 8.40741 18.7014 10.6422 18.6509C11.4419 18.6318 11.8742 18.1348 11.9636 17.1287C12.2155 14.3063 12.6544 7.5505 12.6592 7.48181C12.686 7.06563 12.9827 6.77471 13.315 6.78784ZM9.2199 0C9.96592 0 10.6217 0.625277 10.8143 1.52127L11.0208 2.79506C11.0875 3.21263 11.3823 3.51757 11.7236 3.52427L14.3905 3.52438C14.7269 3.52438 15 3.86379 15 4.28199C15 4.70019 14.7269 5.03959 14.3905 5.03959L11.7474 5.03944C11.7433 5.03954 11.7392 5.03959 11.7351 5.03959L11.7152 5.03858L3.28444 5.03947C3.27789 5.03955 3.27133 5.03959 3.26476 5.03959L3.25225 5.03858L0.609492 5.03959C0.273052 5.03959 0 4.70019 0 4.28199C0 3.86379 0.273052 3.52438 0.609492 3.52438L3.27582 3.52337L3.35791 3.51691C3.6637 3.4676 3.91784 3.17891 3.9799 2.79506L4.17738 1.56673C4.3781 0.625277 5.03392 0 5.77993 0H9.2199ZM9.2199 1.51521H5.77993C5.5849 1.51521 5.41342 1.67784 5.36385 1.91119L5.1745 3.09305C5.15044 3.24275 5.1154 3.38702 5.07055 3.52465H9.92953C9.88463 3.38702 9.84951 3.24275 9.82533 3.09305L9.62786 1.86472C9.58641 1.67784 9.41494 1.51521 9.2199 1.51521Z" fill="#383838" />
        </svg>
    )
}

export default OutlineDeleteIcon