import React from "react";

const MarketingIcon = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9918 0C17.3162 0 19.2784 1.91298 19.2784 5.18761H15.1999V5.22103C13.3071 5.22103 11.7726 6.71705 11.7726 8.56248C11.7726 10.4079 13.3071 11.9039 15.1999 11.9039H19.2784V12.2047C19.2784 15.4375 17.3162 17.3505 13.9918 17.3505H5.28655C1.96211 17.3505 0 15.4375 0 12.2047V5.14584C0 1.91298 1.96211 0 5.28655 0H13.9918ZM18.5586 6.62444C18.9561 6.62444 19.2784 6.9386 19.2784 7.32614V9.76541C19.2737 10.1511 18.9542 10.4626 18.5586 10.4671H15.277C14.3188 10.4797 13.4809 9.84003 13.2635 8.93004C13.1547 8.36516 13.3075 7.78225 13.6809 7.33755C14.0544 6.89285 14.6104 6.63182 15.1999 6.62444H18.5586ZM15.6626 7.75218H15.3456C15.1509 7.74995 14.9635 7.82377 14.825 7.95717C14.6866 8.09058 14.6087 8.27246 14.6087 8.46224C14.6087 8.86038 14.9372 9.18443 15.3456 9.18901H15.6626C16.0695 9.18901 16.3995 8.86736 16.3995 8.47059C16.3995 8.07382 16.0695 7.75218 15.6626 7.75218ZM10.0076 3.75079H4.56683C4.16319 3.75076 3.83466 4.06734 3.82997 4.46084C3.82997 4.85899 4.15849 5.18304 4.56683 5.18761H10.0076C10.4146 5.18761 10.7445 4.86597 10.7445 4.4692C10.7445 4.07243 10.4146 3.75079 10.0076 3.75079Z"
        fill="white"
      />
    </svg>
  );
};

export default MarketingIcon;
