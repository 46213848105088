import React, { FC } from 'react'

const InformationColoredIcon: FC<React.SVGProps<SVGSVGElement>> = ({ className }) => {
    return (
        <svg className={className} width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 13.5C0 6.04395 6.04395 0 13.5 0C20.9561 0 27 6.04395 27 13.5C27 20.9561 20.9561 27 13.5 27C6.04395 27 0 20.9561 0 13.5ZM14.85 8.1C14.85 7.74196 14.7078 7.39858 14.4546 7.14541C14.2014 6.89223 13.858 6.75 13.5 6.75C13.142 6.75 12.7986 6.89223 12.5454 7.14541C12.2922 7.39858 12.15 7.74196 12.15 8.1C12.15 8.45804 12.2922 8.80142 12.5454 9.05459C12.7986 9.30777 13.142 9.45 13.5 9.45C13.858 9.45 14.2014 9.30777 14.4546 9.05459C14.7078 8.80142 14.85 8.45804 14.85 8.1ZM13.5 20.25C13.858 20.25 14.2014 20.1078 14.4546 19.8546C14.7078 19.6014 14.85 19.258 14.85 18.9V13.5C14.85 13.142 14.7078 12.7986 14.4546 12.5454C14.2014 12.2922 13.858 12.15 13.5 12.15C13.142 12.15 12.7986 12.2922 12.5454 12.5454C12.2922 12.7986 12.15 13.142 12.15 13.5V18.9C12.15 19.258 12.2922 19.6014 12.5454 19.8546C12.7986 20.1078 13.142 20.25 13.5 20.25Z" />
        </svg>
    )
}

export default InformationColoredIcon