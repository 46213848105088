import React, { FC } from 'react'

export type ICusStep = {
    id: number,
    label: string,
    icon: React.FC<React.SVGProps<SVGSVGElement>>
}

type props = {
    activeStepId: number
    steps: ICusStep[]
    setActiveStep: React.Dispatch<React.SetStateAction<number>>
}

const CusStepper: FC<props> = ({ steps, activeStepId, setActiveStep }) => {
    return (
        <div className='flex justify-between items-center'>
            {steps.map((stp, i) => (
                <React.Fragment key={`stepper-item--${stp.id}`}>
                    <button onClick={() => setActiveStep(stp.id)} className='flex flex-col gap-y-2 w-96 items-center justify-center -mx-8'>
                        <div className={`transition-colors duration-300 ${activeStepId >= stp.id ? "bg-palette-background-default" : "bg-[#F0F0F0]"} w-12 aspect-square rounded-full flex justify-center items-center z-20`}>
                            <stp.icon className={`transition-colors duration-300 ${activeStepId >= stp.id ? "fill-white" : "fill-[#707070]"} mx-auto`} />
                        </div>
                        <p>{stp.label}</p>
                    </button>
                    {i !== steps.length - 1 && <div className={`h-[2px] transition-colors duration-300 w-full ${activeStepId - 1 < stp.id ? "bg-[#F0F0F0]" : "bg-palette-background-default"} z-10 mb-6`} />}
                </React.Fragment>
            ))}
        </div>
    )
}

export default CusStepper