import React, { FC, useState } from "react";
import { LoginFormPic } from "assets";
import { CusButton, CusInput } from "common/modules";
import { useForm } from "react-hook-form";
import { useYupValidationResolver } from "common/hooks";
import { LoginValidationSchema } from "validations";
import { useNavigate } from "react-router";
import { AdminLoginType } from "common/types";
import { AdminLoginApi } from "api";

type props = {
  className?: string;
};

const LoginCard: FC<props> = ({ className }) => {
  const resolver = useYupValidationResolver(LoginValidationSchema);
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AdminLoginType>({ resolver });

  const onLoginFormSubmit = (data: AdminLoginType) => {
    setLoading(true);
    AdminLoginApi(data)
      .then((res) => {
        if (res) {
          const accessToken = res?.mncotoken;
          const refreshToken = res?.mncoreftoken;
          if (accessToken && refreshToken) {
            localStorage.setItem("access-token", accessToken);
            localStorage.setItem("refresh-token", refreshToken);
            navigate("dashboard");
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  return (
    <div className={`bg-white p-4 rounded-2xl ${className}`}>
      <LoginFormPic className="mx-auto" />
      <form
        className="flex flex-col gap-y-4 mt-6"
        onSubmit={handleSubmit(onLoginFormSubmit)}
      >
        <CusInput
          className="bg-[#F2F2F2] h-12 rounded-lg"
          regFunc={() => register("userName")}
          errorMsg={errors.userName?.message}
          placeHolder="نام کاربری"
          inputClassName="h-12"
          inputStyle={{
            height: "100%",
            backgroundColor: "#F2F2F2",
            paddingRight: "16px",
          }}
        />
        <CusInput
          className="bg-[#F2F2F2] h-12 rounded-lg"
          regFunc={() => register("password")}
          errorMsg={errors.password?.message}
          inputClassName="h-12"
          placeHolder="پسورد"
          inputStyle={{
            height: "100%",
            backgroundColor: "#F2F2F2",
            paddingRight: "16px",
          }}
        />
        <CusButton type="submit" loading={loading}>
          تایید
        </CusButton>
      </form>
    </div>
  );
};

export default LoginCard;
