import React, { FC } from "react";

const LabelIcon: FC<React.SVGProps<SVGSVGElement>> = ({ className }) => {
  return (
    <svg
      className={className}
      width="11"
      height="13"
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.96762 0.28418H0.635631C0.34945 0.28418 0.117188 0.514738 0.117188 0.799969V12.6631L2.19096 11.1158L3.74629 12.6631L5.30162 11.1158L6.85695 12.6631L8.41228 11.1158L10.4861 12.6631V0.799969C10.4861 0.514738 10.2538 0.28418 9.96762 0.28418ZM5.82007 8.53681H2.19096V7.50523H5.82007V8.53681ZM5.82007 6.47365H2.19096V5.44207H5.82007V6.47365ZM5.82007 4.41049H2.19096V3.37892H5.82007V4.41049ZM8.41228 8.53681H6.85695V7.50523H8.41228V8.53681ZM8.41228 6.47365H6.85695V5.44207H8.41228V6.47365ZM8.41228 4.41049H6.85695V3.37892H8.41228V4.41049Z"
        fill="white"
      />
    </svg>
  );
};

export default LabelIcon;
