import DashboardRoutes from "common/router/DashboardRoutes";

export * from "./error";
export * from "./textTransformer"
export * from "./arrays"
export * from "./dates"

export const generateBreadcrumbText = (pathname: string) => {
  if (pathname === "/dashboard") {
    return "گزارشات روزانه";
  } else {
    const selectedRoute = DashboardRoutes.children?.filter(
      (route) => route.path === pathname.split("/").pop()
    );

    if (selectedRoute && selectedRoute[0]) {
      return selectedRoute[0].id;
    } else {
      return "";
    }
  }
};

export const iconBgColorGenerator = (index?: number, transparency?: number) => {
  if (index) {
    if (index === 1 || index === 5 || index === 9) {
      return `#FA5F1C${transparency}`;
    }
    if (index === 2 || index === 6 || index === 10) {
      return `#5ECE07${transparency}`;
    }
    if (index === 3 || index === 7 || index === 11) {
      return `#86BDD4${transparency}`;
    }
    if (index === 4 || index === 8 || index === 12) {
      return `#079ECE${transparency}`;
    }
  }
};
