import { CircleDownArrow } from 'assets'
import { Dropdown } from 'flowbite-react'
import React, { FC } from 'react'

type props = {
    label?: string
    icon?: any
    items?: { name: string, value: number | string }[]
    selected?: { name: string, value: number | string }
    onChange?: (item: { name: string, value: number | string }) => void
    className?: string
    errorMsg?: string
    disabled?: boolean
}

const CusDropdown: FC<props> = ({ label, icon, items, selected, onChange, className, errorMsg, disabled }) => {

    return (
        <div>
            <div className={`dropdown-container px-2 select-none ${className}`}>
                <Dropdown
                    color=""
                    label={label}
                    disabled={disabled}
                    className='w-44 max-h-[30vh] overflow-y-scroll z-[999]'
                    renderTrigger={(t) => <button className='w-full cursor-pointer py-2 text-right'>{selected ? selected.name : label ? label : "انتخاب کنید"}</button>}
                >
                    {items?.map((opt) => <Dropdown.Item disabled={disabled} key={opt.value} onClick={() => onChange?.(opt)} value={opt.value}>{opt.name}</Dropdown.Item>)}
                </Dropdown>
                {icon ? icon : <CircleDownArrow />}
            </div>
            {errorMsg && <p className='text-xs text-rose-600'>{errorMsg}</p>}
        </div>
    )
}

export default CusDropdown