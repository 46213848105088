import { deleteDiscountCode } from "api";
import { OperationIcon, OutlineDeleteIcon } from "assets";
import { PagintaionType } from "common/types";
import { DiscountCodeType } from "common/types/discounts";
import { gregorianToShamsi, persianDateParser } from "common/utils";
import CusTable from "components/CusTable/CusTable";
import { MiniLoading } from "components/common";
import { Pagination, TableCell, TableRow } from "flowbite-react";
import { openedModalType } from "pages/dashboard/MarketingPage";
import React, { FC, useState } from "react";
import { toast } from "react-toastify";

type props = {
  codesData?: DiscountCodeType[]
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  setOpenedModal: React.Dispatch<React.SetStateAction<openedModalType | undefined>>
  pagesMeta?: PagintaionType
  handleDelete: (type: string, value: any) => void
}

const DiscountCode: FC<props> = ({
  page,
  setPage,
  codesData,
  pagesMeta,
  handleDelete,
  setOpenedModal,
}) => {

  const pagesCount = pagesMeta && Math.ceil(pagesMeta?.totalCount / 10)
  const [codesLoading, setCodesLoading] = useState<boolean[]>(Array(codesData?.length).fill(false))

  const onDeleteCode = (code: DiscountCodeType, index: number) => {
    setCodesLoading((prev) => prev.map((v, i) => i === index ? true : v))
    deleteDiscountCode(code).then((res) => {
      if (res.success) {
        handleDelete('discountCode', code.id)
        toast.success(`کد تخفیف ${code.code} با موفقیت حذف شد.`)
      }
    }).finally(() => {
      setCodesLoading(Array(codesData?.length).fill(false))
    })
  }

  return (
    <div>
      <CusTable
        stickyHeader
        noItem={codesData?.length === 0}
        headers={[
          "عنوان",
          "تاریخ انتشار",
          "کد تخفیف",
          "تاریخ شروع",
          "تاریخ اتمام",
          "تعداد استفاده",
          "جزئیات",
        ]}
        contents={
          codesData?.map((item, i) => (
            <TableRow>
              <TableCell>{item.title}</TableCell>
              <TableCell>{persianDateParser(item.publishDate)}</TableCell>
              <TableCell>{item.code}</TableCell>
              <TableCell>{gregorianToShamsi(item.startDate)}</TableCell>
              <TableCell>{gregorianToShamsi(item.endDate)}</TableCell>
              <TableCell>{item.usedBy.length}</TableCell>
              <TableCell className="flex gap-x-2">
                <button className="mr-2 flex justify-center items-center" onClick={() => setOpenedModal({ id: 4, title: "منو رستوران", item })}>
                  <OperationIcon className="fill-black" />
                </button>
                <button onClick={() => onDeleteCode(item, i)} className="mr-2 flex justify-center items-center">
                  {codesLoading[i] ? (
                    <MiniLoading width={8} color="black" />
                  ) : <OutlineDeleteIcon />}
                </button>
              </TableCell>
            </TableRow>
          ))
        }
      />
      <div className="flex justify-end mt-6">
        <Pagination
          dir="ltr"
          nextLabel="بعدی"
          previousLabel="قبلی"
          onPageChange={setPage}
          currentPage={page ?? 1}
          totalPages={pagesCount ?? 1}
        />
      </div>
    </div>
  );
};

export default DiscountCode;
