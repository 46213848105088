import { BASE_API_URL } from "common/constants"

export const urlTranformer = (link: string) => {
    if (link.split("/")[0] === "uploads") {
        return `${BASE_API_URL}${link}`
    }
    return link
}

export const isNumber = (inputText: string): boolean => {
    // Check if the input text can be converted to a number and is not NaN
    return !isNaN(Number(inputText));
}

export function containsPersianCharacters(text: string): boolean {
    // Persian Unicode range: \u0600-\u06FF
    const persianRegex = /[\u0600-\u06FF]/;
    return persianRegex.test(text);
  }