import React from "react";

const TwoPersonIcon = () => {
  return (
    <svg
      width="22"
      height="14"
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 6C5.34 6 4.01 4.66 4.01 3C4.01 1.34 5.34 0 7 0C8.66 0 10 1.34 10 3C10 4.66 8.66 6 7 6ZM15 6C13.34 6 12.01 4.66 12.01 3C12.01 1.34 13.34 0 15 0C16.66 0 18 1.34 18 3C18 4.66 16.66 6 15 6ZM15 8C17.33 8 22 9.17 22 11.5V14H8V11.5C8 9.17 12.67 8 15 8ZM7 8C7.29 8 7.62 8.02 7.97 8.05C6.81 8.89 6 10.02 6 11.5V14H0V11.5C0 9.17 4.67 8 7 8Z"
        fill="white"
      />
    </svg>
  );
};

export default TwoPersonIcon;
