import React, { FC } from "react";

const ChartIcon: FC<React.SVGProps<SVGSVGElement>> = ({ className }) => {
  return (
    <svg
      className={className}
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M16.8769 0H6.13333C2.21822 0 0 2.21822 0 6.13333V16.8667C0 20.7818 2.21822 23 6.13333 23H16.8769C20.792 23 23 20.7818 23 16.8667V6.13333C23 2.21822 20.792 0 16.8769 0Z"
        fill="#0085E6"
      />
      <path
        d="M6.1743 8.47412C5.65297 8.47412 5.22363 8.90345 5.22363 9.43501V17.3368C5.22363 17.8581 5.65297 18.2875 6.1743 18.2875C6.70585 18.2875 7.13519 17.8581 7.13519 17.3368V9.43501C7.13519 8.90345 6.70585 8.47412 6.1743 8.47412Z"
        fill="#0085E6"
      />
      <path
        d="M11.5405 4.70215C11.0192 4.70215 10.5898 5.13148 10.5898 5.66304V17.3368C10.5898 17.8581 11.0192 18.2875 11.5405 18.2875C12.0721 18.2875 12.5014 17.8581 12.5014 17.3368V5.66304C12.5014 5.13148 12.0721 4.70215 11.5405 4.70215Z"
        fill="#0085E6"
      />
      <path
        d="M16.8359 12.645C16.3043 12.645 15.875 13.0743 15.875 13.6059V17.337C15.875 17.8583 16.3043 18.2877 16.8257 18.2877C17.3572 18.2877 17.7866 17.8583 17.7866 17.337V13.6059C17.7866 13.0743 17.3572 12.645 16.8359 12.645Z"
        fill="#0085E6"
      />
    </svg>
  );
};

export default ChartIcon;
