import { SubscriptionType } from 'common/types'
import { gregorianToShamsi, persianDateParser } from 'common/utils'
import CusTable from 'components/CusTable/CusTable'
import { TableCell, TableRow } from 'flowbite-react'
import React, { FC } from 'react'

type props = {
    item?: SubscriptionType
}

const SubscriptionDetail: FC<props> = ({ item }) => {
    return (
        <div className='overflow-y-scroll h-[60vh]'>
            <div className='grid grid-cols-2 lg:grid-cols-3 gap-2 font-light'>
                <div className='flex gap-x-2 justify-center'>
                    <p>بازه زمانی : </p>
                    <p>{item?.subscriptionPeriod?.toString?.()}</p>
                </div>
                <div className='flex gap-x-2 justify-center'>
                    <p>شعاع : </p>
                    <p>{item?.subscriptionRadius?.toString?.()}</p>
                </div>
                <div className='flex gap-x-2 justify-center'>
                    <p>پیام در ماه : </p>
                    <p>{item?.perMonthOfferCount?.toString?.()}</p>
                </div>
                <div className='flex gap-x-2 justify-center'>
                    <p>کل پیام : </p>
                    <p>{item?.totalOfferCount?.toString?.()}</p>
                </div>
                <div className='flex gap-x-2 justify-center'>
                    <p>پیام مانده : </p>
                    <p>{item?.currentOfferCount?.toString?.()}</p>
                </div>
                <div className='flex gap-x-2 justify-center'>
                    <p>تاریخ شروع : </p>
                    <p>{item?.jalaliStartDate?.replaceAll("-", "/")}</p>
                </div>
                <div className='flex gap-x-2 justify-center'>
                    <p>تاریخ پایان : </p>
                    <p>{item?.jalaliEndDate?.replaceAll("-", "/")}</p>
                </div>
                <div className='flex gap-x-2 justify-center'>
                    <p>وضعیت پرداخت : </p>
                    <p>{item?.latestStatus?.toString()}</p>
                </div>
            </div>
            <div className='mt-4'>
                <p className='font-semibold'>اطلاعات پرداخت اشتراک</p>
                <div className='grid grid-cols-2 lg:grid-cols-3 gap-2 font-light'>
                    <div className='flex gap-x-2 justify-center'>
                        <p>شناسه سفارش : </p>
                        <p>{item?.orderNumber ?? "----"}</p>
                    </div>
                    <div className='flex gap-x-2 justify-center'>
                        <p>مبلغ اصلی : </p>
                        <p>{item?.originalPrice}</p>
                    </div>
                    <div className='flex gap-x-2 justify-center'>
                        <p>مبلغ نهایی : </p>
                        <p>{item?.subscriptionCost}</p>
                    </div>
                    <div className='flex gap-x-2 justify-center'>
                        <p>شناسه پیگیری : </p>
                        <p>{item?.trackId ?? "----"}</p>
                    </div>
                    <div className='flex gap-x-2 justify-center'>
                        <p>درصد تخفیف : </p>
                        <p>{item?.discountAmount?.toString?.()}</p>
                    </div>
                    <div className='flex gap-x-2 justify-center'>
                        <p>تاریخ سفارش : </p>
                        <p>{persianDateParser(item?.createdAt)}</p>
                    </div>
                </div>
            </div>
            <div className='mt-4'>
                <p className='mb-2 font-semibold'>اطلاعات پرداخت پکیج ها</p>
                <div>
                    <CusTable
                        stickyHeader
                        headers={["شناسه سفازش", "شناسه پیگیری", "تاریخ", "تعداد", "مبلغ اصلی", "درصد تخفیف", "مبلغ نهایی", "وضعیت"]}
                        contents={
                            item?.packagePaymentDetails?.map((pd) => (
                                <TableRow>
                                    <TableCell>{pd.orderNumber}</TableCell>
                                    <TableCell>شناسه پیگیری</TableCell>
                                    <TableCell>{persianDateParser(pd.createdAt)}</TableCell>
                                    <TableCell>{pd.packageCount}</TableCell>
                                    <TableCell>{pd.originalPrice}</TableCell>
                                    <TableCell>{pd.discountAmount}</TableCell>
                                    <TableCell>
                                        {pd.packageCost}
                                    </TableCell>
                                    <TableCell>
                                        <p className={pd.isPaid ? "text-lime-500" : "text-rose-600"}>  
                                             {pd?.latestStatus?.toString()}
                                        </p>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    />
                </div>
            </div>
        </div>
    )
}

export default SubscriptionDetail