import AxiosInstance from "IAxios/AxiosInstance"
import { CommentType, PagintaionType } from "common/types"
import { toast } from "react-toastify"

export interface CommnetSearchTerms {
    restaurantName?: string
    isPublished?: boolean
    startDate?: string
    endDate?: string
    status?: string
    page?: number
}

interface AllCommentsAPIType extends PagintaionType {
    data?: CommentType[]
}

export const getAllComments = (searchTerms?: CommnetSearchTerms) =>
    AxiosInstance.get<AllCommentsAPIType>("comments", { params: searchTerms })
        .then((res) => res.data)
        .catch((err) => toast.error(err?.response?.data.error))

export const updateCommentStatus = (commentId: string, data: { status: string }) =>
    AxiosInstance.put(`comments/${commentId}/status`, data)
        .then((res) => res.data)
        .catch((err) => toast.error(err?.response?.data.error))