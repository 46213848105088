import React, { FC } from "react";

type props = {
  onClick?: () => void;
  className?: string;
  text?: string;
  icon: any;
};

const ButtonWithIcon: FC<props> = ({ onClick, className, text, icon}) => {
  return (
    <div className="flex items-center gap-x-2">
      <button className={className} onClick={onClick}>
        {icon}
      </button>
      {text && <p>{text}</p>}
    </div>
  );
};

export default ButtonWithIcon;
