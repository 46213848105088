import React, { FC, ReactNode, useCallback, useEffect, useRef } from "react";

interface props {
  children: ReactNode;
  onClickAway: () => void;
}

const ClickAwayListener: FC<props> = ({ children, onClickAway }) => {
  const ref = useRef<HTMLDivElement>(null);
  const secModal = document.getElementById("second-modal")

  const handleClickAway = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (secModal?.contains(e.target as Node)) {
        secModal?.remove()
        return
      }
      if (ref.current && !ref.current.contains(e.target as Node)) {
        onClickAway();
      }
    },
    [onClickAway]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickAway);
    return () => document.removeEventListener("mousedown", handleClickAway);
  }, [handleClickAway]);

  return <div ref={ref}>{children}</div>;
};

export default ClickAwayListener;
