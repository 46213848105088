import { BackendTemplate } from "api";
import AxiosInstance from "../../IAxios/AxiosInstance";
import { AdminLoginType, AdminOverallReportItem, AdminReport, AdminType } from "common/types";
import { toast } from "react-toastify";

export interface AdminLoginApiReturnedType {
  success: boolean;
  mncotoken?: string | null;
  mncoreftoken?: string | null;
}

interface LoggedInAdminAPIType extends BackendTemplate {
  data?: AdminType;
}

export const AdminLoginApi = (data: AdminLoginType) =>
  AxiosInstance.post<AdminLoginApiReturnedType>("auth/admincanfly/login", data)
    .then((res) => res.data)
    .catch((err) => {
      toast.error(err?.response?.data.error);
    });

export const getLoggedInAdmin = () =>
  AxiosInstance.get<LoggedInAdminAPIType>("auth/admincanfly/me")
    .then((res) => res.data)
    .catch((err) => {
      toast(err?.response?.data.error);
    });

export const logoutAdmin = () =>
  AxiosInstance.get("auth/admincanfly/logout")
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error))


interface GetAdminDailyReportAPI extends BackendTemplate {
  data?: AdminReport | null
}

export const getAdminDailyReport = (data: {
  type: "mostsale",
  datePeriod: "year"
}) => AxiosInstance.get<GetAdminDailyReportAPI>("admincanfly/dailyreports", { data })
  .then((res) => res.data)
  .catch((err) => toast.error(err?.response?.data.error))

export type GetAdminOverallReportsAPI = {
  type?: string,
  datePeriod?: string,
}

interface GetAdminOverallReportsReturned extends BackendTemplate {
  data?: AdminOverallReportItem[] | null
}

export const getAdminOverallReports = (data: GetAdminOverallReportsAPI) =>
  AxiosInstance.post<GetAdminOverallReportsReturned>("admincanfly/reports", data)
    .then((res) => res.data)
    .catch((err) => toast.error(err?.response?.data.error))