import { Table, TableCell } from "flowbite-react";
import React, { FC } from "react";

type props = {
  headers?: any[];
  contents?: any;
  withHeaderGrid?: boolean;
  withYDivider?: boolean;
  noItem?: boolean
  stickyHeader?: boolean
  wrapperClassName?: string
};

const CusTable: FC<props> = ({
  contents,
  headers,
  withYDivider,
  withHeaderGrid,
  noItem,
  stickyHeader,
  wrapperClassName
}) => {
  const emptyCell = "EmptyCell";

  return (
    <div className={`rounded-lg border overflow-hidden overflow-y-scroll min-h-48 relative max-h-[60vh] overflow-x-scroll ${wrapperClassName}`}>
      <Table className="text-center">
        <Table.Head className={`bg-palette-background-default ${stickyHeader && "sticky top-0 z-50"}`}>
          {headers?.map((item: string, i) => (
            <Table.HeadCell
              key={item + i}
              className={`py-4 bg-palette-background-default !rounded-none ${item === emptyCell ? "w-16" : ""
                } ${withHeaderGrid ? "border" : ""} ${item === ("نام رستوران") ? "w-48" : ""} ${item === "تاریخ" && "px-14"}`}
            >
              {item === emptyCell ? "" : item}
            </Table.HeadCell>
          ))}
        </Table.Head>
        <Table.Body className={`${withYDivider ? "divide-y" : ""}`}>
          {contents}
        </Table.Body>
      </Table>
      {noItem && <div className="absolute left-1/2 top-1/2 -translate-x-1/2 ">آیتمی وجود ندارد</div>}
    </div>
  );
};

export default CusTable;
