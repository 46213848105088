import {
  ReportsIcon,
  CustomerIcon,
  MarketingIcon,
  DashboardIcon,
  RestaurantIcon,
  MonitoringIcon,
  SubscriptionIcon,
} from "assets";

import TwoPersonIcon from "assets/icons/TwoPersonIcon";
import { DashboardPagesLayout } from "common/layouts";
import { Loadable } from "components/common";

import React, { lazy } from "react";
import { RouteObject } from "react-router";

const DashboardMainPage = Loadable(
  lazy(() => import("pages/dashboard/MainPage"))
);
const RestaurantPage = Loadable(
  lazy(() => import("pages/dashboard/RestaurantPage"))
);
const MarketingPage = Loadable(
  lazy(() => import("pages/dashboard/MarketingPage"))
);
const CustomersPage = Loadable(
  lazy(() => import("pages/dashboard/CustomersPage"))
);
const OwnersPage = Loadable(lazy(() => import("pages/dashboard/OwnersPage")));
const SubscriptionPage = Loadable(
  lazy(() => import("pages/dashboard/SubscriptionPage"))
);
const ReportsPage = Loadable(lazy(() => import("pages/dashboard/ReportsPage")));
const SurveillencePage = Loadable(
  lazy(() => import("pages/dashboard/SurveillencePage"))
);

const DashboardRoutes: RouteObject = {
  path: "/dashboard",
  element: <DashboardPagesLayout />,
  children: [
    {
      path: "",
      element: <DashboardMainPage />,
      id: "دشبورد",
      hydrateFallbackElement: <DashboardIcon />,
    },
    {
      path: "restaurant",
      element: <RestaurantPage />,
      id: "رستوران",
      hydrateFallbackElement: <RestaurantIcon />,
    },
    {
      path: "marketing",
      element: <MarketingPage />,
      id: "بازاریابی",
      hydrateFallbackElement: <MarketingIcon />,
    },
    {
      path: "customers",
      element: <CustomersPage />,
      id: "مشتریان",
      hydrateFallbackElement: <CustomerIcon />,
    },
    {
      path: "owners",
      element: <OwnersPage />,
      id: "صاحبین کسب وکار",
      hydrateFallbackElement: <TwoPersonIcon />,
    },
    {
      path: "subsription",
      element: <SubscriptionPage />,
      id: "اشتراک",
      hydrateFallbackElement: <SubscriptionIcon />,
    },
    {
      path: "reports",
      element: <ReportsPage />,
      id: "گزارشات",
      hydrateFallbackElement: <ReportsIcon />,
    },
    {
      path: "surveillence",
      element: <SurveillencePage />,
      id: "پایش",
      hydrateFallbackElement: <MonitoringIcon />,
    },
  ],
};

export default DashboardRoutes;
