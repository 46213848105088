import React, { FC } from "react";

const SendIcon: FC<React.SVGProps<SVGSVGElement>> = ({ className }) => {
  return (
    <svg
      className={className}
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.62619 6.66646L7.68917 10.0134C7.77942 10.16 7.92045 10.1583 7.97743 10.1504C8.03441 10.1425 8.17148 10.1076 8.22113 9.94115L10.8008 1.22838C10.846 1.07437 10.763 0.969449 10.7258 0.932218C10.6897 0.894986 10.5865 0.815446 10.437 0.857754L1.71799 3.41094C1.55271 3.45946 1.51661 3.59823 1.50871 3.65521C1.50081 3.71331 1.49855 3.85716 1.64466 3.94911L5.02992 6.06624L8.02087 3.0437C8.18502 2.87785 8.45298 2.87616 8.61939 3.04032C8.78581 3.20448 8.78694 3.473 8.62278 3.63885L5.62619 6.66646ZM7.93343 11C7.5408 11 7.1809 10.8003 6.96879 10.4573L4.78171 6.90846L1.19619 4.6661C0.809768 4.4241 0.607814 3.99311 0.670431 3.54013C0.732484 3.08714 1.04331 2.72724 1.47994 2.59918L10.1989 0.045991C10.6 -0.0713452 11.0299 0.0397857 11.3255 0.334254C11.6211 0.631544 11.7311 1.06591 11.6115 1.46869L9.03176 10.1809C8.90258 10.6192 8.54155 10.9289 8.08969 10.9893C8.03666 10.9961 7.98533 11 7.93343 11Z"
        fill="white"
      />
    </svg>
  );
};

export default SendIcon;
