import React, { FC, useState } from 'react'
import { CusButton, CusInput } from 'common/modules'
import { AdvertiseDistanceData } from 'common/types'
import { useForm } from 'react-hook-form'
import { addAdvertiseDistance } from 'api'

type props = {
  handleAddNewItem: (type: "distance" | "types", newItem: number | string) => void
}

const AddAdsDistanceForm: FC<props> = ({ handleAddNewItem }) => {
  const [loading, setLoading] = useState(false)

  const { register, handleSubmit } = useForm<AdvertiseDistanceData>()

  const onSubmit = (data: AdvertiseDistanceData) => {

    setLoading(true)
    addAdvertiseDistance({ distance: Number(data.distance) }).then((res) => {
      if (res.success) {
        handleAddNewItem("distance", Number(data.distance))
      }
    }).finally(() => setLoading(false))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CusInput regFunc={() => register("distance")} placeHolder='شعاع مورد نظر را وارد کنید.' />
      <CusButton loading={loading} className='w-full text-black mt-6' type='submit'>ثبت</CusButton>
    </form>
  )
}

export default AddAdsDistanceForm