import { LoadSettingsFormAPIType, deleteAdvertiseDistance, deleteOfferType } from "api";
import { DeleteIcon, PlusIcon } from "assets";
import { CusButton } from "common/modules";
import { ButtonWithIcon, CusTable } from "components";
import { TableCell, TableRow } from "flowbite-react";
import { openedModalType } from "pages/dashboard/MarketingPage";
import React, { FC, useEffect, useState } from "react";

type props = {
  settings?: Partial<LoadSettingsFormAPIType>
  openedModal?: openedModalType
  setOpenedModal: React.Dispatch<React.SetStateAction<openedModalType | undefined>>
  handleDelete: (type: string, value: any) => void
}

const MarketingSettings: FC<props> = ({ settings, setOpenedModal, openedModal, handleDelete }) => {

  const [distLoadings, setDistLoadings] = useState<boolean[]>([]);
  const [typesLoadings, setTypesLoadings] = useState<boolean[]>([])

  useEffect(() => {
    if (settings?.data?.advDistance) {
      setDistLoadings(Array(settings?.data?.advDistance.length).fill(false))
    }
    if (settings?.data?.advTypes) {
      setTypesLoadings(Array(settings?.data?.advTypes.length).fill(false))
    }
  }, [settings?.data?.advDistance, settings?.data?.advTypes])


  const onDelete = async (type: "distance" | "types", value: number | string, index: number) => {
    if (type === "distance" && typeof value === "number") {
      setDistLoadings((prev) => prev.map((v, i) => i === index ? true : v));
      deleteAdvertiseDistance({ distance: value }).then((res) => {
        if (res.success) {
          handleDelete(type, value)
        }
      })
    }
    if (type === "types" && typeof value === "string") {
      setTypesLoadings((prev) => prev.map((v, i) => i === index ? true : v));
      deleteOfferType({ offerType: value }).then((res) => {
        if (res.success) {
          handleDelete(type, value)
        }
      })
    }
  }

  return (
    <div className="flex gap-x-6 justify-between">
      <div className="grow">
        <ButtonWithIcon
          onClick={() => setOpenedModal({ title: "افزودن شعاع تبلیغ", id: 1 })}
          text="افزودن شعاع"
          className="btn-with-icon-yellow"
          icon={<PlusIcon fill="white" />}
        />
        <div className="mt-4">
          <CusTable
            stickyHeader
            noItem={settings?.data?.advDistance?.length === 0}
            headers={["شعاع تبلیغات", "عملیات"]}
            contents={
              settings?.data?.advDistance?.map((item, i) => (
                <TableRow>
                  <TableCell>{item}</TableCell>
                  <TableCell className="flex justify-center">
                    <CusButton
                      loading={distLoadings[i]}
                      onClick={() => onDelete("distance", item, i)}
                      className="p-2 bg-rose-300 justify-center items-center w-8 h-8 rounded-full bg-opacity-70 drop-shadow-md">
                      <DeleteIcon className="scale-150" />
                    </CusButton>
                  </TableCell>
                </TableRow>
              ))
            } />
        </div>
      </div>
      <div className="grow">
        <ButtonWithIcon
          onClick={() => setOpenedModal({ title: "افزودن نوع تبلیغ", id: 2 })}
          text="افزودن نوع تبلیغ"
          className="btn-with-icon-yellow"
          icon={<PlusIcon fill="white" />}
        />
        <div className="mt-4">
          <CusTable
            stickyHeader
            headers={["انواع تبلیغات", "عملیات"]}
            noItem={settings?.data?.advTypes?.length === 0}
            contents={
              settings?.data?.advTypes?.map((item, i) => (
                <TableRow>
                  <TableCell>{item}</TableCell>
                  <TableCell className="flex justify-center">
                    <CusButton
                      loading={typesLoadings[i]}
                      onClick={() => onDelete("types", item, i)}
                      className="p-2 bg-rose-300 justify-center items-center w-8 h-8 rounded-full bg-opacity-70 drop-shadow-md">
                      <DeleteIcon className="scale-150" />
                    </CusButton>
                  </TableCell>
                </TableRow>
              ))
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MarketingSettings;
