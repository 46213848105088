import { RouteObject, useRoutes } from "react-router";

//routes
import AuthenticationRoutes from "./LoginRoutes";
import DashboardRoutes from "./DashboardRoutes";
import RootPage from "pages/RootPage";

const rootPages: RouteObject = {
  path: "/",
  element: <RootPage />,
};

// ================ || combine and render routes || ================
export default function ThemeRoutes() {
  return useRoutes([rootPages, AuthenticationRoutes, DashboardRoutes]);
}
