import { CusInput } from 'common/modules'
import { OrderType } from 'common/types/orders'
import React, { FC } from 'react'

type props = {
    order?: OrderType
}

const RestaurantOrdersDetail: FC<props> = ({ order }) => {
    if (order) {
        const discountsItem = order?.orderFullDetails?.orginalPrice - order?.orderFullDetails?.cartPrice || 0;
        const MinimumPurchaseDiscount =
            order?.orderFullDetails?.cartPrice * (order?.orderFullDetails?.PurchaseLimit?.percentage / 100) || 0;

        const newCartPrice = order?.orderFullDetails?.cartPrice - MinimumPurchaseDiscount;
        const VIPCustomerDiscount = newCartPrice * (order?.orderFullDetails?.vipCustomer?.percentage / 100) || 0;
        
        return (
            <div className='flex flex-col gap-y-4 h-auto overflow-y-auto'> 
                <p>جزییات سفارش</p>
                <div className='grid grid-cols-3 rounded-xl overflow-hidden shadow-md border'>
                    <p className='text-center bg-palette-background-default py-4'>نام غذا</p>
                    <p className='text-center bg-palette-background-default py-4'>تعداد</p>
                    <p className='text-center bg-palette-background-default py-4'>مبلغ</p>
                    {
                        order?.orderFullDetails.items.map((ord) => (
                            <React.Fragment key={ord.id}>
                                <p className='text-center bg-lightGray py-4'>{ord.name}</p>
                                <p className='text-center bg-lightGray py-4'>{ord.count}</p>
                                <p className='text-center bg-lightGray py-4'>{ord.price}</p>
                            </React.Fragment>
                        ))
                    }
                </div>
                <div className='flex flex-col gap-y-2 border rounded-xl p-4'>
                    <div className='flex justify-between'>
                        <p className='text-sm text-slate-600 font-light'>توضیحات :</p>
                        <p className='text-sm text-slate-600'>{order?.note}</p>
                    </div>
                    <div className='flex justify-between'>
                        <p className='text-sm text-slate-600 font-light'>شماره میز :</p>
                        <p className='text-sm text-slate-600'>{order?.tableNumber}</p>
                    </div>
                    <hr className='-mx-4 my-4' />
                    <div className='flex justify-between'>
                        <p className='text-sm text-slate-600 font-light'>مبلغ خرید :</p>
                        <p className='text-sm text-slate-600'>{order?.orderFullDetails.orginalPrice}</p>
                    </div>
                    <p className='text-sm'>تخفیفات</p>
                    <div className='flex justify-between'>
                        <p className='text-sm text-slate-600 font-light'>تخفیفات آیتم های منو :</p>
                        <p className='text-sm text-slate-600'>{discountsItem}</p>
                    </div>
                    <div className='flex justify-between'>
                        <p className='text-sm text-slate-600 font-light'>تخفیفات مشتری VIP :</p>
                        <p className='text-sm text-slate-600'>{VIPCustomerDiscount}</p>
                    </div>
                    <div className='flex justify-between'>
                        <p className='text-sm text-slate-600 font-light'>تخفیف حداقل خرید :</p>
                        <p className='text-sm text-slate-600'>{MinimumPurchaseDiscount}</p>
                    </div>
                    {order.orderFullDetails.discountCodeAmount > 0 && <div className='flex justify-between'>
                        <p className='text-sm text-slate-600 font-light'>مبلغ کد تخفیف :</p>
                        <p className='text-sm text-slate-600'>{order.orderFullDetails.discountCodeAmount}</p>
                    </div>}
                    <div className='flex justify-between'>
                        <p className='text-sm text-slate-600 font-light'>جمع کل (ارزش افزوده) :</p>
                        <p className='text-sm text-slate-600'>{order.orderCost}</p>
                    </div>
                </div>
            </div>
        )
    }
    return <></>
}

export default RestaurantOrdersDetail
