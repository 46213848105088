import { MiniLoading } from "components/common";
import { Button } from "flowbite-react";
import React, { FC } from "react";

type props = {
  children: any;
  loading?: boolean;
  type?: "submit" | "button" | "reset";
  onClick?: () => void;
  className?: string;
  loadingWidth?: number
  loadingHeight?: number
  loadingColor?: string
};

const CusButton: FC<props> = ({
  loading,
  children,
  type,
  onClick,
  className,
  loadingWidth,
  loadingColor = "white"
}) => {
  return (
    <Button
      type={type}
      onClick={onClick}
      disabled={loading}
      className={`bg-palette-background-default enabled:hover:bg-palette-background-default/80 py-2 text-white ${className}`}
    >
      {loading ? <MiniLoading width={loadingWidth} color={loadingColor} /> : children}
    </Button>
  );
};

export default CusButton;
