import React, { FC } from 'react'

const PenOutline: FC<React.SVGProps<SVGSVGElement>> = ({ className }) => {
    return (
        <svg
            className={className}
            width="15"
            height="14"
            viewBox="0 0 15 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.4231 12.8911C14.7415 12.8911 15 13.1395 15 13.4455C15 13.7516 14.7415 13.9999 14.4231 13.9999H8.844C8.52554 13.9999 8.26709 13.7516 8.26709 13.4455C8.26709 13.1395 8.52554 12.8911 8.844 12.8911H14.4231ZM10.8584 0.483166C10.8969 0.511995 12.1838 1.47294 12.1838 1.47294C12.6514 1.74053 13.0168 2.21879 13.1553 2.78501C13.293 3.34531 13.193 3.92336 12.8722 4.41196C12.8701 4.41522 12.8679 4.41844 12.8605 4.42802L12.8547 4.43542C12.8027 4.50136 12.5763 4.77638 11.434 6.15171C11.4234 6.16974 11.4114 6.18662 11.3982 6.20302C11.379 6.2272 11.358 6.24943 11.3356 6.26967C11.2577 6.36394 11.1754 6.463 11.0889 6.56714L10.9136 6.77816C10.5518 7.21352 10.1227 7.72978 9.61366 8.3421L9.3524 8.65635C8.3696 9.83843 7.11093 11.3519 5.49854 13.2904C5.14547 13.7132 4.61625 13.9593 4.0478 13.966L1.24864 14H1.24095C0.974033 14 0.741731 13.8241 0.679425 13.5735L0.0494406 11.0078C-0.0805563 10.4763 0.0486713 9.9278 0.403278 9.50203L7.64925 0.792887C7.65233 0.78993 7.65464 0.786234 7.65772 0.783278C8.45231 -0.129622 9.8892 -0.264155 10.8584 0.483166ZM6.84137 3.5385L1.30403 10.1946C1.17249 10.3528 1.12403 10.5569 1.17249 10.7527L1.69632 12.8853L4.03396 12.8572C4.25626 12.855 4.46164 12.7596 4.59779 12.597C5.29892 11.754 6.1801 10.6945 7.08608 9.60505L7.40661 9.2196L7.72769 8.83348C8.57734 7.81168 9.41674 6.80208 10.1191 5.95703L6.84137 3.5385ZM8.54616 1.49068L7.56212 2.67217L10.8396 5.09001C11.4704 4.33069 11.8854 3.83059 11.9238 3.78291C12.0499 3.58629 12.0991 3.30835 12.033 3.04077C11.9653 2.76653 11.7876 2.53368 11.5315 2.3851C11.4769 2.34888 10.1807 1.38202 10.1407 1.35171C9.65305 0.976206 8.94153 1.04125 8.54616 1.49068Z"
                fill="#1E202C"
            />
        </svg>

    )
}

export default PenOutline