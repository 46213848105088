import React, { FC, useEffect, useState } from 'react'
import { motion } from "framer-motion"

type props = {
    label?: string;
    onText?: string;
    offText?: string;
    defaultValue?: boolean
    className?: string
    onChange?: (isOn: boolean) => void
}

const CusToggle: FC<props> = ({ label, offText, onText, defaultValue, className, onChange }) => {
    const [isOn, setIsOn] = useState(defaultValue)

    const toggleSwitch = () => {
        const newIsOn = !isOn;
        onChange?.(newIsOn);
        setIsOn(newIsOn);
    };


    return (
        <div className={`flex items-center justify-between select-none gap-x-4 ${className}`}>
            {label && <label className="text-gray-700">{label}:</label>}
            <div className="">
                <div className="relative">
                    <motion.div
                        className={`w-12 h-6 transition-colors duration-300 flex items-center justify-between rounded-full p-1 cursor-pointer ${isOn ? 'justify-end bg-palette-background-default' : 'justify-start bg-gray-300'}`}
                        onClick={toggleSwitch}
                        layout
                        transition={{ type: "spring", stiffness: 700, damping: 30 }}
                    >
                        {isOn && <p className='select-none text-xs'>{onText ?? 'ON'}</p>}
                        <motion.div
                            className="w-4 h-4 bg-white rounded-full drop-shadow-md"
                            layout
                        />
                        {!isOn && <p className='select-none text-xs'>{offText ?? "OFF"}</p>}
                    </motion.div>
                </div>
            </div>
        </div >
    )
}

export default CusToggle