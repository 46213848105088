export const WEEK_DAYS = [
    {
        id: 0,
        name: "شنبه"
    },
    {
        id: 1,
        name: "یکشنبه"
    },
    {
        id: 2,
        name: "دوشنبه"
    },
    {
        id: 3,
        name: "سه شنبه"
    },
    {
        id: 4,
        name: "چهارشنبه"
    },
    {
        id: 5,
        name: "پنجشنبه"
    },
    {
        id: 6,
        name: "جمعه"
    },
]