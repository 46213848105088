import React, { FC, useEffect, useState } from 'react'
import TimeKeeper from "react-timekeeper"
import { AnimatePresence, motion } from "framer-motion"
import { ClickAwayListener } from 'components'
import CusInput from '../CusInput'
import "./CusTimePicker.css"

type props = {
    value?: string
    onChange?: any
    noButton?: boolean
    open?: boolean
}

const CusTimePicker: FC<props> = ({ value, onChange, noButton, open }) => {

    const [isOpen, setIsOpen] = useState<boolean>(noButton ?? false)

    useEffect(() => {
        if (open) {
            setIsOpen(true)
        }
    }, [open])

    return (
        <div className='relative'>
            <AnimatePresence>
                {!noButton && <button onClick={() => setIsOpen(true)}>
                    <CusInput value={value} dir='ltr' />
                </button>}
                {isOpen && (
                    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                        <motion.div
                            key={"time-keeper"}
                            className='absolute top-0 -right-16 z-[999]'
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.3 }}
                        >
                            <TimeKeeper onChange={onChange} />
                        </motion.div>
                    </ClickAwayListener>
                )}
            </AnimatePresence>
        </div>
    )
}

export default CusTimePicker