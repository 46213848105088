import React, { FC } from "react";

type props = {
  title?: string;
  tabs?: any[];
  handleSelectTab?: (i: number | string) => void;
  selectedTab?: number;
  UpComponent?: any;
  MainComponent?: any;
  className?: string;
  cardPaperStyles?: React.CSSProperties;
  titleIcon?: any;
};

const Card: FC<props> = ({
  tabs,
  title,
  className,
  UpComponent,
  titleIcon,
  selectedTab,
  MainComponent,
  handleSelectTab,
  cardPaperStyles,
}) => {
  return (
    <div className={className}>
      {tabs && (
        <div className="flex w-full z-[1] -mb-4">
          {tabs?.map((tab, i) => (
            <button
              key={tab + i}
              onClick={() => handleSelectTab?.(i)}
              className={`rounded-t-lg pb-5 pt-2 px-8 transition-colors duration-300 ${i > 0 ? "-mr-3" : ""
                } ${selectedTab === i ? "bg-palette-background-default" : "bg-white"
                }`}
            >
              {tab.name ?? tab}
            </button>
          ))}
        </div>
      )}
      <div
        className="w-full h-full rounded-lg bg-white px-6 py-4 relative"
        style={cardPaperStyles}
      >
        {title && (
          <div className="mb-4">
            <div className={`mb-4 ${titleIcon && "flex items-center gap-x-4"}`}>
              {titleIcon && titleIcon}
              <h4>{title}</h4>
            </div>
            <hr />
          </div>
        )}
        {UpComponent && <div className="my-3">{UpComponent}</div>}
        <div>{MainComponent}</div>
      </div>
    </div>
  );
};

export default Card;
