import React, { FC, useEffect, useState } from 'react'
import Card from 'components/Card/Card'
import Search from 'components/Search/Search'
import { MiniLoading } from 'components/common'
import { OrderType } from 'common/types/orders'
import CusTable from 'components/CusTable/CusTable'
import { Pagination, TableCell, TableRow } from 'flowbite-react'
import { PagintaionType } from 'common/types'
import { OperationIcon } from 'assets'

export type restaurantOrdersSearchType = {
  inputData?: string,
  startDate?: string,
  endDate?: string
}

type props = {
  page?: number
  setOrders?: any
  loading?: boolean
  orders?: OrderType[]
  ordersMeta?: PagintaionType
  setOpenedModal?: React.Dispatch<any>
  searchTerms?: restaurantOrdersSearchType
  getOrders?: (searchTerms?: restaurantOrdersSearchType, page?: number) => Promise<void>
  setSearchTerms?: React.Dispatch<React.SetStateAction<restaurantOrdersSearchType | undefined>>
}

const RestaurantOrders: FC<props> = ({
  page,
  orders,
  loading,
  setOrders,
  getOrders,
  ordersMeta,
  searchTerms,
  setSearchTerms,
  setOpenedModal
}) => {

  const [currentPage, setCurrentPage] = useState<number>(1)

  const handleSearchSubmit = () => {
    getOrders?.(searchTerms)
  }

  useEffect(() => {
    getOrders?.(searchTerms, currentPage)
  }, [currentPage])


  return (
    <div>
      <Card
        UpComponent={
          <Search
            withDate
            textInputClassName='w-[300px]'
            endDate={searchTerms?.endDate}
            onSearchSubmit={handleSearchSubmit}
            startingDate={searchTerms?.startDate}
            inputPlaceHolder='نام رستوران شماره سفارش کد رهگیری'
            onInputChange={(v) => setSearchTerms?.((prev) => ({ ...prev, inputData: v }))}
            setendDate={(v) => setSearchTerms?.((prev) => ({ ...prev, endDate: v }))}
            setStartingDate={(v) => setSearchTerms?.((prev) => ({ ...prev, startDate: v }))}
          />
        }
        MainComponent={
          <div className="relative">
            {loading && (
              <div
                className="absolute left-0 top-0 bottom-10 right-0 bg-white z-50 flex justify-center items-center bg-opacity-45 backdrop-blur-[1px]"
              >
                <MiniLoading />
              </div>
            )}
            <CusTable
              stickyHeader
              noItem={orders?.length === 0}
              headers={[
                "نام رستوران",
                "نام مشتری",
                "شماره سفارش",
                "کد رهگیری",
                "مبلغ",
                "تاریخ",
                "زمان",
                "وضعیت سفارش",
                "جزئیات",
              ]}
              contents={
                orders?.map((or) => (
                  <TableRow key={or._id}>
                    <TableCell>{or.restaurantName}</TableCell>
                    <TableCell>{or.customerName}</TableCell>
                    <TableCell>{or.orderNumber}</TableCell>
                    <TableCell>{or.trackId}</TableCell>
                    <TableCell>{or.orderFullDetails.cartPrice}</TableCell>
                    <TableCell>{or.axillaryDate.day}</TableCell>
                    <TableCell>{or.preparationDate.split("T")[1].slice(0, 8)}</TableCell>
                    <TableCell>{or.orderStatus.currentStatus}</TableCell>
                    <TableCell>
                      <button onClick={() => setOpenedModal?.({ id: 5, item: or })}>
                        <OperationIcon className='fill-black' />
                      </button>
                    </TableCell>
                  </TableRow>
                ))
              }
            />
            <div className='flex justify-end mt-6'>
              <Pagination
                dir="ltr"
                nextLabel="بعدی"
                previousLabel="قبلی"
                totalPages={page ?? 1}
                onPageChange={setCurrentPage}
                currentPage={currentPage ?? 1}
              />
            </div>
          </div>
        }
      />
    </div>
  )
}

export default RestaurantOrders