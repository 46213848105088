import React, { FC, useEffect, useState } from 'react'
import { InputDatePicker } from "jalaali-react-date-picker"
import { CusInput, CusTimePicker } from 'common/modules';
import { IoTimeOutline } from 'react-icons/io5';

import "jalaali-react-date-picker/lib/styles/index.css";
import "./style.css"
import { normalizeTimeFormat } from 'common/utils';

type props = {
    errorMsg?: string
    className?: string
    disabled?: boolean
    withClock?: boolean
    placeholder?: string
    defaultValue?: string
    inputClassName?: string
    wrapperClassName?: string
    timePickerClassName?: string
    onChange: (date?: string, time?: string, miladiDate?: string) => void,
}

const CusDatepicker: FC<props> = ({
    disabled,
    onChange,
    errorMsg,
    className,
    withClock,
    defaultValue,
    inputClassName,
    wrapperClassName,
    timePickerClassName,
    placeholder = 'تاریخ و زمان انتشار',
}) => {

    const [value, setValue] = useState<any>()
    const [time, setTime] = useState<string>()

    const [selectedDateTimeStr, setSelectedDateTimeStr] = useState<string>()

    const [openClock, setOpenClock] = useState<boolean>(false)
    const [openDatePicker, setOpenDatePicker] = useState<boolean>(false)

    const handleChange = (date: any) => {
        const mounth = date?.jMonth().toString().length === 2 ? date?.jMonth() : `0${date?.jMonth()}`
        const day = date?.jDate().toString().length === 2 ? date?.jDate() : `0${date?.jDate()}`

        if (!withClock) {
            setSelectedDateTimeStr(`${date?.jYear()}-${Number(mounth) + 1}-${day}`)
        }

        setValue(date)
        // onChange(`${date?.jYear()}-${mounth}-${day}`, time)
    }

    useEffect(() => {
        setOpenDatePicker(false)
    }, [value])

    useEffect(() => {
        // if (isFirstRender.current) {
        //     isFirstRender.current = false;
        //     return; // Skip the first render
        // }
        setSelectedDateTimeStr(defaultValue)
    }, [defaultValue])

    useEffect(() => {
        if (value) {
            setOpenClock(true)
        }
    }, [openDatePicker])

    useEffect(() => {
        if (value || time)
            onChange(`${value?.jYear?.()}-${value?.jMonth?.() + 1}-${value?.jDate?.()}`, time, value?.toISOString().slice(0, 10))
    }, [value, time])

    // raise an event that show datepicker is opened
    const datepickerOpenedEvent = new CustomEvent('datepickerOpened', {
        detail: {
            message: {
                msg: `Datepicker has been ${openDatePicker ? "opened" : "closed"}`,
                isOpen: openDatePicker
            },
            timestamp: new Date(),
        },
    });

    useEffect(() => {
        window.dispatchEvent(datepickerOpenedEvent)
    }, [openDatePicker])

    return (
        <div className={wrapperClassName}>
            <div className='flex bg-flbite-bg pl-4 rounded-lg'>
                <button disabled={disabled} className='flex-1' onClick={() => setOpenDatePicker((prev) => !prev)}>
                    <CusInput disabled={disabled} className={`text-center pr-2 ${inputClassName}`} value={selectedDateTimeStr} placeHolder={placeholder} />
                </button>
                {value && withClock && <button id='clock-btn' onClick={() => setOpenClock((prev) => !prev)}>
                    <IoTimeOutline />
                </button>}
            </div>
            <InputDatePicker
                value={value}
                disabled={disabled}
                open={openDatePicker}
                style={{ border: 0 }}
                onChange={handleChange}
                placeholder={placeholder}
                wrapperClassName='invisible'
                className={`bg-flbite-bg ${className}`}
                onClick={() => setOpenDatePicker((prev) => !prev)}
            />
            {withClock && openClock && <div className={`flex justify-end w-3/4 ${timePickerClassName}`}>
                <CusTimePicker
                    noButton
                    open={openClock}
                    onChange={(e: any) => {
                        setTime(e.formatted24)
                        setSelectedDateTimeStr(`${value?.jYear?.()}-${value?.jMonth?.() + 1}-${value?.jDate?.()} - ${normalizeTimeFormat(e?.formatted24) ?? ""}`)
                    }}
                />
            </div>}
            {errorMsg && <p className='text-xs text-rose-600 -mt-8'>{errorMsg}</p>}
        </div>

    )
}

export default CusDatepicker