// src/Sidebar.tsx
import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import DashboardRoutes from "common/router/DashboardRoutes";
import AdminCard from "components/AdminCard/AdminCard";
import { useSelector } from "react-redux";
import { RootState } from "store/store";

type props = {
  isExpanded: boolean;
};

const pages = DashboardRoutes.children;

const Sidebar: FC<props> = ({ isExpanded }) => {
  const { pathname } = useLocation();

  const { userName, role } = useSelector((state: RootState) => state.user)

  // const isSelected = (path: string) => {
  //   if (path === "" && pathname === "/dashboard") {
  //     return true;
  //   }
  //   return pathname.split("/").pop() === path;
  // };

  const thisPageIndex =
    pages?.findIndex((item) => item.path === pathname.split("/").pop()) ?? 0;

  const indicatorLoactionCoefficient = thisPageIndex === -1 ? 0 : thisPageIndex;

  // ${
  //   isSelected(page.path ?? "")
  //     ? "bg-palette-background-default/15"
  //     : ""
  // }

  return (
    <motion.div
      className="bg-palette-background-main text-white min-h-screen p-4 hidden md:block"
      animate={{ width: isExpanded ? 250 : 70 }}
      initial={{ width: 70 }}
      transition={{ duration: 0.3 }}
    >
      {isExpanded && (
        <AdminCard name={userName ?? ""} headline={role} />
      )}

      <nav className="relative">
        <ul>
          {pages?.map?.((page) => (
            <li key={page.path} className={`my-2 px-2 rounded-lg`}>
              <Link
                dir="rtl"
                className="flex items-center gap-x-6 w-full h-14"
                to={page.path ?? "#"}
              >
                {page.hydrateFallbackElement}
                {isExpanded && <p>{page.id}</p>}
              </Link>
            </li>
          ))}
        </ul>
        <motion.div
          layout
          layoutId="indicator"
          initial={{ top: 0 }}
          animate={{ top: indicatorLoactionCoefficient * 64 }}
          className="absolute left-0 right-0 top-0 h-14 rounded-lg bg-palette-background-default/15"
        />
      </nav>
    </motion.div>
  );
};

export default Sidebar;
