import React, { FC } from "react";

const PlusIcon: FC<React.SVGProps<SVGSVGElement>> = ({ className, fill }) => {
  return (
    <svg
      className={className}
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 2.70213V20.4043M2 11.5532H18"
        stroke={fill ?? "black"}
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PlusIcon;
