import React from "react";

import {
  Checkbox,
  Label,
  Radio,
  Select,
  TextInput,
  Textarea,
} from "flowbite-react";

type props = {
  ibName?: string;
  regFunc?: Function;
  className?: string;
  inputClassName?: string;
  type?: "select" | "date" | "text" | "radio" | "checkbox" | "textarea";
  options?: any[];
  setter?: any;
  name?: string;
  inputType?: string;
  value?: any;
  inputStyle?: React.CSSProperties;
  placeHolder?: string;
  errorMsg?: string;
  fullWidth?: boolean;
  leftIcon?: any
  dir?: "rtl" | "ltr"
  disabled?: boolean
  min?: string | number
  withBorder?: boolean
  id?: string
  ref?: React.Ref<HTMLInputElement> | React.Ref<HTMLSelectElement> | React.Ref<HTMLInputElement> | React.Ref<HTMLTextAreaElement>
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
};

const CusInput = ({
  id,
  ref,
  dir,
  min,
  name,
  type,
  value,
  ibName,
  setter,
  regFunc,
  options,
  errorMsg,
  leftIcon,
  disabled,
  fullWidth,
  className,
  inputType,
  onKeyDown,
  withBorder,
  inputStyle,
  placeHolder,
  inputClassName,
}: props) => {
  if (type === "checkbox") {
    return (
      <div className={className}>
        <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
          {ibName}
        </label>
        <Checkbox {...regFunc?.()} />
      </div>
    );
  }

  if (type === "radio") {
    return (
      <fieldset className={className}>
        <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
          {ibName}
        </label>
        {options?.map((item) => (
          <div key={item?.name} className="flex items-center gap-2">
            <Radio
              id={item?.name}
              value={item?.name}
              name={name}
              onChange={(e) => setter?.(e.target.value)}
            />
            <Label>{item?.name}</Label>
          </div>
        ))}
      </fieldset>
    );
  }

  // if (type === 'date') {
  //     return (
  //         <div className={className}>
  //             <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">{ibName}</label>
  //             <DatePicker
  //                 locale='fa'
  //                 direction='rtl'
  //                 inputClass='w-full rounded-lg'
  //                 onChange={(e) => setter(e.value)}
  //             />
  //         </div>
  //     )
  // }

  if (type === "select") {
    return (
      <div className={className}>
        <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
          {ibName}
        </label>
        <Select {...regFunc?.()} onChange={(e) => setter?.(e.target.value)} style={inputStyle} defaultValue={value}>
          {/* <option value={0}>
            وضعیت
          </option> */}
          {options?.map((item) => (
            <option key={item.id} value={item?.value ?? 0}>
              {item?.name}
            </option>
          ))}
        </Select>
      </div>
    );
  }

  if (type === "textarea") {
    return (
      <div className={className}>
        <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
          {ibName}
        </label>
        <Textarea
          value={value}
          onChange={(e) => setter?.(e.target.value)}
          className={inputClassName}
          {...regFunc?.()}
          rows={1}
        />
      </div>
    );
  }

  return (
    <div className={fullWidth ? "w-full" : ""}>
      <div className={className}>
        {ibName && (
          <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
            {ibName}
          </label>
        )}
        <div className={`${leftIcon && "flex pl-4"} items-center gap-x-2 w-full bg-flbite-bg rounded-lg`}>
          <TextInput
            id={id}
            min={min}
            dir={dir}
            value={value}
            onKeyDown={onKeyDown}
            ref={ref}
            type={inputType}
            {...regFunc?.()}
            disabled={disabled}
            placeholder={placeHolder}
            className={`flex-1 ${inputClassName}`}
            onChange={(e) => setter?.(e.target.value)}
            style={{ ...inputStyle, border: !withBorder && 0 }}
          />
          {leftIcon && leftIcon}
        </div>
        {errorMsg && <p className="text-xs text-red-500">{errorMsg}</p>}
      </div>
    </div>
  );
};

export default CusInput;
