import React, { FC } from 'react'

const MultiplicationIcon: FC<React.SVGProps<SVGSVGElement>> = ({ className }) => {
    return (
        <svg className={className} width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 0.5C2.73812 0.5 0.5 2.73875 0.5 5.5C0.5 8.26125 2.73812 10.5 5.5 10.5C8.26188 10.5 10.5 8.26125 10.5 5.5C10.5 2.73875 8.26188 0.5 5.5 0.5ZM7.81687 6.93313C7.93407 7.05032 7.99991 7.20926 7.99991 7.375C7.99991 7.54074 7.93407 7.69968 7.81687 7.81687C7.69968 7.93407 7.54074 7.99991 7.375 7.99991C7.20926 7.99991 7.05032 7.93407 6.93313 7.81687L5.5 6.38375L4.06687 7.81687C4.00897 7.87512 3.94012 7.92133 3.86428 7.95287C3.78845 7.98441 3.70713 8.00065 3.625 8.00065C3.54287 8.00065 3.46155 7.98441 3.38572 7.95287C3.30988 7.92133 3.24103 7.87512 3.18313 7.81687C3.12503 7.75888 3.07895 7.69001 3.0475 7.61418C3.01606 7.53836 2.99987 7.45708 2.99987 7.375C2.99987 7.29292 3.01606 7.21164 3.0475 7.13582C3.07895 7.05999 3.12503 6.99112 3.18313 6.93313L4.61625 5.5L3.18313 4.06687C3.06593 3.94968 3.00009 3.79074 3.00009 3.625C3.00009 3.45926 3.06593 3.30032 3.18313 3.18313C3.30032 3.06593 3.45926 3.00009 3.625 3.00009C3.79074 3.00009 3.94968 3.06593 4.06687 3.18313L5.5 4.61625L6.93313 3.18313C7.05032 3.06593 7.20926 3.00009 7.375 3.00009C7.54074 3.00009 7.69968 3.06593 7.81687 3.18313C7.93407 3.30032 7.99991 3.45926 7.99991 3.625C7.99991 3.79074 7.93407 3.94968 7.81687 4.06687L6.38375 5.5L7.81687 6.93313Z" fill="black" />
        </svg>
    )
}

export default MultiplicationIcon