import React from "react";

const ReportsIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1048 1.60225C12.0688 1.82724 12.0508 2.05224 12.0508 2.27724C12.0508 4.30221 13.6888 5.93928 15.7048 5.93928C15.9298 5.93928 16.1458 5.91318 16.3708 5.87718V13.1392C16.3708 16.191 14.5708 18 11.5108 18H4.86083C1.79997 18 0 16.191 0 13.1392V6.48017C0 3.42022 1.79997 1.60225 4.86083 1.60225H12.1048ZM12.2857 7.07416C12.0418 7.04716 11.7997 7.15516 11.6548 7.35316L9.47776 10.1701L6.9839 8.20815C6.8309 8.09115 6.6509 8.04525 6.4709 8.06415C6.29181 8.09115 6.12981 8.18925 6.02091 8.33325L3.35785 11.7991L3.30295 11.8801C3.14995 12.1672 3.22195 12.5362 3.49195 12.7351C3.61795 12.8161 3.75294 12.8701 3.90594 12.8701C4.11384 12.8791 4.31094 12.7702 4.43693 12.6001L6.6959 9.69223L9.26086 11.6191L9.34186 11.6722C9.62986 11.8252 9.98985 11.7541 10.1968 11.4832L12.7978 8.12715L12.7618 8.14515C12.9058 7.94715 12.9328 7.69515 12.8338 7.47016C12.7357 7.24516 12.5188 7.09216 12.2857 7.07416ZM15.8309 0C17.0278 0 17.9998 0.971985 17.9998 2.16897C17.9998 3.36595 17.0278 4.33793 15.8309 4.33793C14.6339 4.33793 13.6619 3.36595 13.6619 2.16897C13.6619 0.971985 14.6339 0 15.8309 0Z"
        fill="white"
      />
    </svg>
  );
};

export default ReportsIcon;
