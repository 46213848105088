import React, { FC } from "react";
import { SearchIcon } from "assets";
import { CusInput } from "common/modules";
import CusDatepicker from "components/CusDatepicker/CusDatepicker";
import { formatDate } from "common/utils";

type props = {
  endDate?: string;
  withDate?: boolean
  fullWidth?: boolean;
  startingDate?: string;
  inputPlaceHolder?: string;
  textInputClassName?: string;
  onSearchSubmit?: () => void;
  inputsContainerWidth?: string;
  setendDate?: (v: string) => void;
  onInputChange?: (v: string) => void
  setStartingDate?: (v: string) => void;
};

const Search: FC<props> = ({
  withDate,
  fullWidth,
  setendDate,
  onInputChange,
  onSearchSubmit,
  setStartingDate,
  inputPlaceHolder,
  textInputClassName,
  inputsContainerWidth,
}) => {
  return (
    <div className={`flex gap-x-1 h-11 ${fullWidth && "justify-between"}`}>
      <div
        className={`flex gap-x-2 ${fullWidth && "grow w-full"}`}
        style={{
          flexGrow: "0",
          width: inputsContainerWidth,
        }}
      >
        <CusInput
          fullWidth={fullWidth}
          setter={onInputChange}
          placeHolder={inputPlaceHolder}
          className={textInputClassName}
          inputStyle={{ height: "40px", paddingRight: "12px" }}
        />
        {withDate && (
          <>
            <CusDatepicker
              placeholder="تاریخ شروع"
              onChange={(_, n, mDate) => {
                if (mDate) {
                  const nDate = new Date(mDate)
                  nDate.setDate(nDate.getDate() + 1)
                  const [year, month, day] = nDate.toISOString().split("T")[0].split("-")
                  const modifiedMiladiDate = `${year}-${month}-${day}`
                  setStartingDate?.(formatDate(modifiedMiladiDate) ?? "")
                }
              }}
            />
            <CusDatepicker
              placeholder="تاریخ اتمام"
              onChange={(_, n, mDate) => {
                if (mDate) {
                  const nDate = new Date(mDate)
                  nDate.setDate(nDate.getDate() + 1)
                  const [year, month, day] = nDate.toISOString().split("T")[0].split("-")
                  const modifiedMiladiDate = `${year}-${month}-${day}`
                  setendDate?.(formatDate(modifiedMiladiDate) ?? "")
                }
              }}
            />
          </>
        )}
      </div>
      <button
        onClick={onSearchSubmit}
        className="bg-palette-background-default rounded-lg aspect-square h-10 flex justify-center items-center"
      >
        <SearchIcon />
      </button>
    </div>
  );
};

export default Search;
