import React, { FC, useState } from "react";
import { DownArrow, HamburgerMenuIcon } from "assets";
import AdminCard from "components/AdminCard/AdminCard";
import { Dropdown } from "flowbite-react";
import { logoutAdmin } from "api";
import { useDispatch, useSelector } from "react-redux";
import { removeUser } from "store/slices/userSlice";
import { useNavigate } from "react-router";
import MiniLoading from "../MiniLoading/MiniLoading";
import { RootState } from "store/store";

type props = {
  toggleSidebar: () => void;
};

const Navbar: FC<props> = ({ toggleSidebar }) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { userName } = useSelector((state: RootState) => state.user)

  const [logoutLoading, setLogoutLoading] = useState<boolean>(false)

  const handleLogoutAdmin = () => {
    setLogoutLoading(true)
    logoutAdmin().then((res) => {
      dispatch(removeUser())
      localStorage.clear()
    }).then(() => navigate("/")).finally(() => setLogoutLoading(false))
  }

  return (
    <div className="bg-white py-4 px-6 flex items-center justify-between drop-shadow-xl">
      <AdminCard
        name=""
        Button={
          <Dropdown className="w-44" label={userName} renderTrigger={() => (
            <button className="flex items-center justify-between gap-x-4">
              <p className="text-sm">{userName}</p>
              <DownArrow className="w-3 h-3" />
            </button>
          )}
          >
            <Dropdown.Item onClick={handleLogoutAdmin}>
              {logoutLoading ? <MiniLoading /> : "خروج"}
            </Dropdown.Item>
          </Dropdown>
        }
      />
      <HamburgerMenuIcon onClick={toggleSidebar} />
    </div>
  );
};

export default Navbar;
