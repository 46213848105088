import React from 'react'

const MonitoringIcon = () => {
    return (
        <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.75 3V18H25.25V3H2.75ZM2.75 0.5H25.25C25.913 0.5 26.5489 0.763392 27.0178 1.23223C27.4866 1.70107 27.75 2.33696 27.75 3V18C27.75 18.6625 27.4875 19.3 27.0125 19.7625C26.55 20.2375 25.9125 20.5 25.25 20.5H16.5V23H19V25.5H9V23H11.5V20.5H2.75C2.0875 20.5 1.45 20.2375 0.9875 19.7625C0.5125 19.3 0.25 18.6625 0.25 18V3C0.25 1.6125 1.3625 0.5 2.75 0.5ZM12.55 9.1625C12.9375 8.7875 13.4625 8.5625 14 8.5625C14.5375 8.575 15.0625 8.7875 15.45 9.175C15.825 9.55 16.05 10.075 16.05 10.6125C16.05 11.1625 15.825 11.675 15.45 12.0625C15.0625 12.45 14.5375 12.6625 14 12.6625C13.4625 12.6625 12.9375 12.4375 12.55 12.0625C12.175 11.675 11.95 11.1625 11.95 10.6125C11.95 10.075 12.175 9.55 12.55 9.1625ZM11.5875 13C11.8999 13.3245 12.2746 13.5827 12.6891 13.759C13.1037 13.9354 13.5495 14.0263 14 14.0263C14.4505 14.0263 14.8963 13.9354 15.3109 13.759C15.7254 13.5827 16.1001 13.3245 16.4125 13C17.05 12.375 17.4125 11.5125 17.4125 10.6125C17.4125 9.7125 17.05 8.8375 16.4125 8.2C15.775 7.5625 14.9 7.2 14 7.2C13.1 7.2 12.225 7.5625 11.5875 8.2C10.95 8.8375 10.5875 9.7125 10.5875 10.6125C10.5875 11.5125 10.95 12.375 11.5875 13ZM6.5 10.6125C7.09234 9.10585 8.12453 7.81242 9.46221 6.90057C10.7999 5.98872 12.3811 5.50069 14 5.5C17.4125 5.5 20.325 7.625 21.5 10.6125C20.9119 12.122 19.8807 13.4183 18.5421 14.3308C17.2035 15.2432 15.62 15.7293 14 15.725C10.5875 15.725 7.675 13.625 6.5 10.6125Z" fill="#F8F8F8" />
        </svg>
    )
}

export default MonitoringIcon