import React, { FC } from 'react'

type props = {
    label?: string
    width?: number | string
    className?: string
}

const DropDownLabel: FC<props> = ({ label, width, className }) => {
    return (
        <div className={className} style={{ width }}>{label}</div>
    )
}

export default DropDownLabel